import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import cvPDF from "../../Assets/cv.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(window.innerWidth);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
  }

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Row className="justify-content-center">
        <Button variant="primary" href={cvPDF} target="_blank">
          <AiOutlineDownload />
          &nbsp;Download CV
        </Button>
      </Row>

      <Row className="resume justify-content-center">
        <Col md={8} className="resume-pdf-container">
          <Document
            file={cvPDF}
            options={{}}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageIndex={pageNumber - 1} scale={width > 768 ? 1.5 : 1} />
          </Document>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Button variant="primary" href={cvPDF} target="_blank">
          <AiOutlineDownload />
          &nbsp;Download CV
        </Button>
      </Row>
    </Container>
  );
}

export default ResumeNew;
